import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { HtmlPage, HtmlPagesList } from '../types/htmlPagesList.type';
import { PrismicHttpPageId } from '../types/httpPageId';

export const getPrismicHttpPageContent = (
  pageId: PrismicHttpPageId,
  data: HtmlPagesList | undefined
): HtmlPage => {
  const defaultPage = {
    id: '',
    htmlTitle: [{ text: pageId?.toString() || '', type: 'header 1', spans: [] }],
    htmlContent: [
      { text: i18n._(t({ id: 'NO_DESCRIPTION_AVAILABLE' })), type: 'paragraph', spans: [] },
    ],
  };

  if (data === undefined) {
    return defaultPage;
  }

  switch (pageId) {
    case 'CASAQUE PLATINE':
      return data.find(pages => pages.id === 'casaque-platine') || defaultPage;
    case 'CASAQUE OR':
      return data.find(pages => pages.id === 'casaque-or') || defaultPage;
    case 'CASAQUE ARGENT':
      return data.find(pages => pages.id === 'casaque-argent') || defaultPage;
    case 'CASAQUE BRONZE':
      return data.find(pages => pages.id === 'casaque-bronze') || defaultPage;
    case 'PRIVACY POLICY':
      return data.find(pages => pages.id === 'politique_de_confidentialite') || defaultPage;
    case 'TERMS AND CONDITIONS OF USE':
      return data.find(pages => pages.id === 'conditions_generales_dutilisation') || defaultPage;
    case 'TERMS AND CONDITIONS OF USE RELATIONAL PROGRAM':
      return (
        data.find(
          pages => pages.id === 'conditions_generales_dutilisation_du_programme_relatoionnel'
        ) || defaultPage
      );
    default:
      return defaultPage;
  }
};
