import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { margin } from '@pmu/portail-partenaire-design-system';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export const AccountActivation: FunctionComponent = () => {
  const { i18n } = useLingui();

  return <Container>{i18n._(t({ id: 'ACCOUNT_ACTIVIATION_TITLE' }))}</Container>;
};

const Container = styled.div({
  padding: margin * 5,
});
