import { SharedEnvironment } from '../../../../../environment/types/environment.type';
import { adaptRefreshTokenResponse } from './adapters/adaptRefreshTokenResponse';
import { refreshTokenApiBuilder } from './refreshTokenApi';
import {
  buildRefreshTokenRequestConfig,
  RefreshTokenRequestConfig,
} from './refreshTokenRequestConfig';
import { RefreshTokenResponseData } from './types/RefreshTokenResponseData';

export interface RefreshTokenConnectorParams {
  refreshToken: string;
}

export const refreshTokenConnectorBuilder = (refreshTokenConfig: RefreshTokenRequestConfig) => {
  const refreshTokenCall = refreshTokenApiBuilder(refreshTokenConfig);

  return ({ refreshToken }: RefreshTokenConnectorParams): Promise<RefreshTokenResponseData> => {
    return refreshTokenCall(refreshToken).then(adaptRefreshTokenResponse);
  };
};

export function buildRefreshTokenConnector(env: SharedEnvironment) {
  return refreshTokenConnectorBuilder(buildRefreshTokenRequestConfig(env));
}
