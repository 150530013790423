import { useQuery } from 'react-query';
import { GenericHook } from '../../../../common/hooks/genericHook.type';
import { useInARowQueries } from '../../../../common/hooks/useInARowQueries';
import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { buildPrismicQueryHook } from '../../../../prismic/http/queries/buildPrismicQueryHook';
import { HtmlPagesList } from '../../../types/htmlPagesList.type';
import { getHtmlPagesConnector } from './getHtmlPageListConnector';

export type UseHtmlPagesQuery = GenericHook<ReturnType<typeof buildHtmlPagesQueryHook>>;

export const buildHtmlPagesQueryHook = (urlOptions: UrlOptions) => {
  const usePrismicQuery = buildPrismicQueryHook(urlOptions);

  return () => {
    const { prismicMasterReference, prismicApiInformationQueryResult } = usePrismicQuery();

    const htmlPagesQueryResult = useQuery<HtmlPagesList, Error, HtmlPagesList>(
      ['HtmlPages'],
      () => getHtmlPagesConnector(prismicMasterReference as string, urlOptions),
      {
        enabled: prismicMasterReference !== undefined,
      }
    );

    return useInARowQueries([prismicApiInformationQueryResult, htmlPagesQueryResult]);
  };
};
