import { RefetchOptions, UseQueryResult } from 'react-query';

export const useInARowQueries = <Data>(
  queryResults: [...UseQueryResult<unknown, Error>[], UseQueryResult<Data, Error>]
): Pick<UseQueryResult<Data, Error>, 'data' | 'error' | 'isLoading' | 'refetch'> => {
  const lastQueryResult = queryResults[queryResults.length - 1] as UseQueryResult<Data, Error>;

  // TODO: remove Promise.all
  const refetchData = async (options?: RefetchOptions) => {
    await Promise.all(queryResults.map(({ refetch }) => refetch(options)));

    return lastQueryResult;
  };

  return {
    refetch: refetchData,
    error: queryResults.find(({ error }) => error !== null)?.error || null,
    data: lastQueryResult.data,
    isLoading: queryResults.some(({ isLoading }) => isLoading),
  };
};
