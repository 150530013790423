import { SharedEnvironment } from './types/environment.type';

// TODO: [CPART-15309] Mettre à jour COGNITO_ENDPOINT_CLIENT_ID sur rc5 et rc3
export const env: SharedEnvironment = {
  API_GATEWAY_ENDPOINT_URL: 'https://api.partenaire.re5.pmu.fr',
  COGNITO_ENDPOINT_URL: 'https://cognito-idp.eu-west-3.amazonaws.com',
  COGNITO_ENDPOINT_HEADER_TARGET: 'AWSCognitoIdentityProviderService.InitiateAuth',
  COGNITO_ENDPOINT_HEADER_CONTENT_TYPE: 'application/x-amz-json-1.1',
  COGNITO_ENDPOINT_CLIENT_ID: '4bohlg82o39f8rb7slhua6e44i',
  PRISMIC_ENDPOINT_URL: 'https://pmu-partenaires-development.prismic.io/api/v2',
  PRISMIC_PERMANENT_ACCESS_TOKEN:
    'MC5ZY0JkZXhBQUFDTUFyWHVp.H--_ve-_ve-_vQ8i77-9Ke-_ve-_vTVU77-9C--_vQLvv71sPu-_vUg777-977-9ViclGu-_vW3vv73vv70',
  IZZILI_ESPACE_GESTION_URL: 'https://espacegestionportailpartenaire.myizzili.com',
  PULSE_URL: 'https://pmu.pulse-systems.com',
};
