import { FunctionComponent } from 'react';

import { colors, margin, textStyles } from '@pmu/portail-partenaire-design-system';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import { getPrismicHttpPageContent } from 'shared/modules/prismicHtmlPages/helpers/getPrismicPageContent';
import styled from 'styled-components';
import { useHtmlPagesQuery } from '../../modules/prismicHtmlPages';

export const PrivacyPolicyPage: FunctionComponent = () => {
  const { data, isLoading } = useHtmlPagesQuery();

  const { htmlContent } = getPrismicHttpPageContent('PRIVACY POLICY', data);

  return (
    <PageContainer>
      <PrivacyPolicyContainer>
        {/* TODO : extract messages.js & messages.po to shared to use them in both web & native */}
        <PageTitle>Politique de confidentialité</PageTitle>
        {/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
        {!isLoading && <PrismicRichText field={htmlContent as RichTextField} />}
      </PrivacyPolicyContainer>
    </PageContainer>
  );
};

const PageTitle = styled.div({
  ...textStyles.TitreCoursesLarge,
  color: colors.green2,
  marginBottom: margin * 4,
  marginTop: margin * 4,
});

const PageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PrivacyPolicyContainer = styled.div({
  width: '60%',
});
