import { useQuery } from 'react-query';
import { GenericHook } from '../../../../common/hooks/genericHook.type';
import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { PrismicApiInformation } from '../../../types/PrismicApiInformation.type';
import { getPrismicApiInformationConnector } from './getPrismicApiInformationConnector';

export type UsePrismicApiInformationQuery = GenericHook<
  ReturnType<typeof buildPrismicApiInformationQueryHook>
>;

export const buildPrismicApiInformationQueryHook = (urlOptions: UrlOptions) => () =>
  useQuery<PrismicApiInformation, Error, PrismicApiInformation>(['PrismicApiInformation'], () =>
    getPrismicApiInformationConnector(urlOptions)
  );
