import { CrashlyticsServiceInterface } from 'shared/lib/crashlytics/interface';

class CrashlyticsService implements CrashlyticsServiceInterface {
  enable = () => {
    //
  };

  disable = () => {
    //
  };

  recordError = (_: Error) => undefined;

  setUser = (_: string) => Promise.resolve();
}

export const CrashlyticsServiceInstance = new CrashlyticsService();
