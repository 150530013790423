import { FunctionComponent } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { useSaveReferrerPage } from '../modules/authentification/hooks/useSaveReferrerPage';
import { UnauthenticatedRoutePath } from '../modules/authentification/types/UnauthenticatedRoutePath.type';
import { AccountActivation } from '../pages/authentification/AccountActivation/AccountActivationPage';
import { AuthCallback } from '../pages/authentification/AuthCallback/AuthCallbackPage';
import { FirstConnection } from '../pages/authentification/FirstConnection/FirstConnectionPage';
import { Login } from '../pages/authentification/Login/LoginPage';
import { CguPage } from '../pages/Cgus/CguPage';
import { PrivacyPolicyPage } from '../pages/PrivacyPolicy/PrivacyPolicyPage';

const UnauthenticatedAppRoutes: FunctionComponent = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: UnauthenticatedRoutePath.cgu,
      element: <CguPage />,
    },
    {
      path: UnauthenticatedRoutePath.privacyPolicy,
      element: <PrivacyPolicyPage />,
    },
    {
      path: UnauthenticatedRoutePath.login,
      element: <Login />,
    },
    {
      path: UnauthenticatedRoutePath.firstConnection,
      element: <FirstConnection />,
    },
    {
      path: UnauthenticatedRoutePath.accountActivation,
      element: <AccountActivation />,
    },
    {
      path: UnauthenticatedRoutePath.authCallback,
      element: <AuthCallback />,
    },
  ]);

  useSaveReferrerPage();

  return routes;
};

export const UnauthenticatedAppRouter: FunctionComponent = () => {
  return (
    <Router>
      <UnauthenticatedAppRoutes />
    </Router>
  );
};
