import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

export const Cheatcode: FunctionComponent = () => {
  const { i18n } = useLingui();

  return <Container>{i18n._(t({ id: 'TRANSLATION_TEST' }))}</Container>;
};

const Container = styled.div({
  padding: 50,
});
