import { useEffect, useState } from 'react';
import { ConnectorOptions } from '../../../../common/types/connectorOptions.type';
import { decodeAccessToken } from '../../../helpers/decodeAccessToken/decodeAccessToken';
import { AuthTokens } from '../../../services/authTokens/types';
import { GlobalUser } from '../../../types/user';

export type UseLoginState = ReturnType<typeof buildUseLoginState>;

const computeIsLoggedIn = (authTokens: AuthTokens | null) => {
  return Boolean(authTokens && authTokens.accessToken);
};

export const buildUseLoginState = (connectorOptions: ConnectorOptions) => () => {
  const [globalUser, setGlobalUser] = useState<GlobalUser>({
    isLoggedIn: false,
    pointOfSales: null,
    currentPointOfSale: null,
    role: null,
    isLoading: true,
  });

  const setCurrentPointOfSale = (pointOfSaleId: string): boolean => {
    const pointOfSale = globalUser.pointOfSales?.find(pos => pos.id === pointOfSaleId);

    if (!pointOfSale) {
      connectorOptions.crashlyticsService?.recordError(
        new Error(
          `Trying to set a point of sale (${pointOfSaleId}) not found in the user points of sale`
        )
      );

      return false;
    }
    setGlobalUser(oldGlobalUser => ({
      ...oldGlobalUser,
      currentPointOfSale: pointOfSale,
    }));

    return true;
  };

  useEffect(() => {
    connectorOptions.authTokensService
      .getAuthTokens()
      .then(async authTokens => {
        if (authTokens) {
          const decodedAccessToken = decodeAccessToken(authTokens.accessToken);
          setGlobalUser({
            isLoading: false,
            isLoggedIn: computeIsLoggedIn(authTokens),
            ...decodedAccessToken,
            currentPointOfSale: decodedAccessToken.pointOfSales[0],
          });
          // TODO: set Analytics user like after login
        } else {
          setGlobalUser(oldGlobalUser => ({
            ...oldGlobalUser,
            isLoading: false,
          }));
        }
      })
      .catch(() => {
        setGlobalUser(oldGlobalUser => ({
          ...oldGlobalUser,
          isLoading: false,
        }));
      });
  }, []);

  return { globalUser, setGlobalUser, setCurrentPointOfSale };
};
