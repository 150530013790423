import { RequestError } from './requestError';

export class BadRequestError extends RequestError {
  constructor(message?: string) {
    super(message, 400);
  }
}

export const isBadRequestErrorGuard = (error: Error | null): error is BadRequestError =>
  error instanceof BadRequestError;
