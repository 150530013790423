import wretch from 'wretch';
import { handleRequestError } from '../../../../../ui/api/error/helpers/handleRequestError';
import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { RawPrismicApiInformation } from './types/RawPrismicApiInformation.type';
import { getPrismicApiInformationUrl } from './url';

export const getPrismicApiInformationApi = async (
  urlOptions: UrlOptions
): Promise<RawPrismicApiInformation> => {
  try {
    const url = getPrismicApiInformationUrl(urlOptions);

    return await wretch(url).get().json();
  } catch (e) {
    return handleRequestError(e);
  }
};
