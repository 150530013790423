/* eslint-disable @typescript-eslint/consistent-type-assertions */
import jwtDecode from 'jwt-decode';
import { roleMapping } from '../../http/mutations/login/formatters/RoleMapping';
import { DecodedAccessToken } from '../../http/mutations/login/types/DecodedAccessToken.type';
import {
  RawDecodedAccessToken,
  RawParsedPointOfSaleUserDataList,
} from '../../http/mutations/login/types/RawDecodedAccessToken.type';

export const decodeAccessToken = (accessToken: string): DecodedAccessToken => {
  const {
    AccessPdvAndContract,
    UserAcceptedTermsOfUse: areCGUValidated,
    UserAcceptedRelationalTermsOfUse: areCGURelationalProgramValidated,
    exp,
  } = jwtDecode<RawDecodedAccessToken>(accessToken);

  const pointOfSaleUserDataList = JSON.parse(
    AccessPdvAndContract
  ) as RawParsedPointOfSaleUserDataList;

  /* TODO: Remove the Partenaire default value
  The role value from Salesforce might be undefined in prod environement, but not in dev environement. They will soon make it mandatory in prod as well.
  In the meantime, in prod in our app, the access control feature to be enabled.
  So we added this default value, that might only be used in prod if the user doesn't have any role. The default value then won't be used in the app since in prod the access control feature is disabled */
  const role = pointOfSaleUserDataList[0].role || 'Partenaire';

  return {
    pointOfSales: pointOfSaleUserDataList.map(({ pdvId, contractId }) => ({
      id: pdvId,
      contractId,
    })),
    role: roleMapping[role],
    areCGUValidated: areCGUValidated === 'true',
    areCGURelationalProgramValidated: areCGURelationalProgramValidated === 'true',
    isExpired: Number(exp) < new Date().valueOf() / 1000,
  } as DecodedAccessToken;
};
