import { SharedEnvironment } from './types/environment.type';

export const env: SharedEnvironment = {
  API_GATEWAY_ENDPOINT_URL: 'https://pprt.1.int.pmutech.fr',
  COGNITO_ENDPOINT_URL: 'https://cognito-idp.eu-west-3.amazonaws.com',
  COGNITO_ENDPOINT_HEADER_TARGET: 'AWSCognitoIdentityProviderService.InitiateAuth',
  COGNITO_ENDPOINT_HEADER_CONTENT_TYPE: 'application/x-amz-json-1.1',
  COGNITO_ENDPOINT_CLIENT_ID: '70s3tpp5ed61bd8fboel474t76',
  PRISMIC_ENDPOINT_URL: 'https://pmu-partenaires-development.prismic.io/api/v2',
  PRISMIC_PERMANENT_ACCESS_TOKEN:
    'MC5ZYVg0WkJNQUFDSUF1OFc0.I1Pvv71SHQvvv71yFu-_vQx377-9Fu-_vUhXCQLvv73vv71F77-977-977-977-9M--_vWdIH2Y',
  IZZILI_ESPACE_GESTION_URL: 'https://espacegestionportailpartenaire.myizzili.com',
  PULSE_URL: 'https://pmu.pulse-systems.com',
};
