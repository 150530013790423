import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthenticationResultKeys } from 'shared/modules/authentication/types/AuthenticationResultKeys.enum';
import { useConnectUserByAccessToken } from '../../../modules/authentification/hooks/useConnectUserByAccessToken';

export const AuthCallback: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const accessToken: string = searchParams.get(AuthenticationResultKeys.ACCESS_TOKEN) || '';
  const refreshToken: string = searchParams.get(AuthenticationResultKeys.REFRESH_TOKEN) || '';

  const options = {
    onError: () => undefined,
  };

  useConnectUserByAccessToken({ accessToken, refreshToken, expiresIn: 0 }, options);

  return null;
};
