import { BadRequestError } from 'shared/ui/api/error/badRequestError';
import { RequestError } from 'shared/ui/api/error/requestError';
import { WretcherError } from 'wretch';
import { NetworkError } from '../networkError';
import { TooManyRequestError } from '../tooManyRequestError';

export const handleRequestError = (error: WretcherError | unknown): never => {
  if (isError(error) && error.message.includes('Network request failed')) {
    throw new NetworkError();
  }

  if (!isWretcherError(error)) {
    throw error;
  }

  if (error.status === 400) {
    throw new BadRequestError(error.message);
  }

  if (error.status === 429) {
    throw new TooManyRequestError(error.message);
  }

  throw new RequestError(error.message, Number(error.status));
};

const isWretcherError = (error: WretcherError | unknown): error is WretcherError => {
  return (
    error != null &&
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'message') &&
    Object.prototype.hasOwnProperty.call(error, 'status')
  );
};

const isError = (error: unknown): error is Error => {
  return (
    error != null &&
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'message')
  );
};
