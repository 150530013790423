import wretch from 'wretch';
import { handleRequestError } from '../../../../../ui/api/error/helpers/handleRequestError';
import { adaptRefreshTokenRequestParams } from './adapters/adaptRefreshTokenRequestParams';
import { RefreshTokenRequestConfig } from './refreshTokenRequestConfig';
import { RawRefreshTokenResponseData } from './types/RawRefreshTokenResponseData';

export const refreshTokenApiBuilder =
  (refreshTokenConfig: RefreshTokenRequestConfig) =>
  async (refreshToken: string): Promise<RawRefreshTokenResponseData> => {
    const { url, authFlow, clientId } = refreshTokenConfig;
    const { contentType, target } = refreshTokenConfig.headers;

    const body = adaptRefreshTokenRequestParams(refreshToken, authFlow, clientId);

    try {
      return await wretch(url)
        .headers({
          'Content-Type': contentType,
          'X-Amz-Target': target,
        })
        .post(body)
        .json();
    } catch (e) {
      return handleRequestError(e);
    }
  };
