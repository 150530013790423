import { AuthTokens } from '../../../services/authTokens/types';
import { RefreshTokenResponseData } from '../../mutations/refreshToken/types/RefreshTokenResponseData';

export const mergeAuthTokens = (
  response: RefreshTokenResponseData,
  authTokens: AuthTokens
): AuthTokens => {
  const { refreshToken } = authTokens;
  const { accessToken, expiresIn } = response.authenticationResult;

  return {
    accessToken,
    expiresIn,
    refreshToken,
  };
};
