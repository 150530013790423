import { AllMessages, i18n } from '@lingui/core';
import { fr } from 'make-plural/plurals';
import frenchCatalog from '../../locales/fr/messages';

const translationCatalogs: AllMessages = {
  fr: frenchCatalog.messages,
};

i18n.loadLocaleData('fr', { plurals: fr });
i18n.load(translationCatalogs);
i18n.activate('fr');
