import { handleRequestError } from '../../../../../ui/api/error/helpers/handleRequestError';
import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { getSearchApi } from '../../../../news/http/queries/baseSearchApi';
import { RawHtmlPagesList } from './types/RawHtmlPagesList.type';

const getPredicates = (): string[] => {
  return ['[[at(document.type,"page_html")]]'];
};

export const getPageHtmlListApi = async (
  prismicEnvironmentRef: string,
  urlOptions: UrlOptions
): Promise<RawHtmlPagesList> => {
  try {
    const predicates = getPredicates();

    return await getSearchApi(urlOptions, prismicEnvironmentRef, predicates)
      .query({ fetchLinks: 'category.name' })
      .get()
      .json();
  } catch (e) {
    return handleRequestError(e);
  }
};
