import { useEffect } from 'react';
import { AuthTokens } from 'shared/modules/authentication/services/authTokens/types';
import { useLoginRoutine } from '../web.implementation';

export interface useConnectUserByAccessTokenOptions {
  onError?: () => void;
}

export const useConnectUserByAccessToken = (
  authTokens: AuthTokens,
  options: useConnectUserByAccessTokenOptions
) => {
  const { handleLogin } = useLoginRoutine();
  useEffect(() => {
    if (authTokens) {
      handleLogin(authTokens);
    } else {
      options.onError?.();
    }
  }, []);
};
