import { env as preprod } from './index.preprod';
import { env as production } from './index.production';
import { env as rc3 } from './index.rc3';
import { env as rc5 } from './index.rc5';
import { env as staging } from './index.staging';
import { env as testing } from './index.testing';

export const sharedEnvironments = { testing, staging, rc3, preprod, production, rc5 };

export type EnvironmentNames = keyof typeof sharedEnvironments;

export const listOfEnvironments = Object.keys(sharedEnvironments) as EnvironmentNames[];
