import { SharedEnvironment } from '../../../../../environment/types/environment.type';
import { AccessRequestConfig } from '../../types/AccessRequestConfig';

export type RefreshTokenRequestConfig = AccessRequestConfig & {
  authFlow: 'REFRESH_TOKEN_AUTH';
};

export function buildRefreshTokenRequestConfig(env: SharedEnvironment): RefreshTokenRequestConfig {
  return {
    url: env.COGNITO_ENDPOINT_URL,
    headers: {
      target: env.COGNITO_ENDPOINT_HEADER_TARGET,
      contentType: env.COGNITO_ENDPOINT_HEADER_CONTENT_TYPE,
    },
    authFlow: 'REFRESH_TOKEN_AUTH',
    clientId: env.COGNITO_ENDPOINT_CLIENT_ID,
  };
}
