import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { HttpClientProvider } from 'shared/modules/common/http/HttpClientProvider';
import './lib/i18n/i18n';
import { ReferrerContextProvider } from './modules/authentification/components/AuthenticationCallbackContext';
import { AuthenticationContextProvider } from './modules/authentification/web.implementation';
import { RootNavigator } from './router/RootNavigator';

function App() {
  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <HttpClientProvider>
        <AuthenticationContextProvider>
          <ReferrerContextProvider>
            <RootNavigator />
          </ReferrerContextProvider>
        </AuthenticationContextProvider>
      </HttpClientProvider>
    </I18nProvider>
  );
}

export default App;
