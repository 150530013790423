import { colors } from '@pmu/portail-partenaire-design-system';
import { FunctionComponent } from 'react';
import { ActivityIndicator } from 'react-native';
import { useAuthenticationContext } from 'shared/modules/authentication/context/AuthenticationContext/AuthenticationContext';
import styled from 'styled-components';

interface RootNavigatorContextProps {
  AuthenticatedNavigator: FunctionComponent;
  UnauthenticatedNavigator: FunctionComponent;
  AdditionalComponents?: FunctionComponent;
}

export const AuthenticationSwitchNavigator: FunctionComponent<RootNavigatorContextProps> = ({
  AuthenticatedNavigator,
  UnauthenticatedNavigator,
}) => {
  const { globalUser } = useAuthenticationContext();

  if (globalUser.isLoading) {
    return (
      <LoaderContainer>
        <ActivityIndicator size="large" color={colors.green4} />
      </LoaderContainer>
    );
  }

  return <>{globalUser.isLoggedIn ? <AuthenticatedNavigator /> : <UnauthenticatedNavigator />}</>;
};

const LoaderContainer = styled.div({
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: '100%',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});
