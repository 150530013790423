import { useMutation } from 'react-query';
import { GenericHook } from '../../../../common/hooks/genericHook.type';
import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { requestNewPasswordConnector } from './requestNewPasswordConnector';
import { RequestNewPasswordMutationParameters } from './types/RequestNewPasswordMutationParameters';
import { RequestNewPasswordResponse } from './types/RequestNewPasswordMutationResponse';

export type UseRequestNewPasswordMutation = GenericHook<
  ReturnType<typeof buildRequestNewPasswordMutationHook>
>;

export const buildRequestNewPasswordMutationHook =
  (urlOptions: UrlOptions) => (onSuccess: () => void, onError: (error: Error) => void) =>
    useMutation<RequestNewPasswordResponse, Error, RequestNewPasswordMutationParameters>(
      'RequestNewPassword',
      (params: RequestNewPasswordMutationParameters) => {
        return requestNewPasswordConnector(params, urlOptions);
      },
      { onSuccess, onError }
    );
