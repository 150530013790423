import { RequestError } from './requestError';

export class TooManyRequestError extends RequestError {
  constructor(message?: string) {
    super(message, 429);
  }
}

export const isTooManyRequestErrorGuard = (error: Error | null): error is TooManyRequestError =>
  error instanceof TooManyRequestError;
