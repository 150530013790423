import { AnalyticsServiceInterface } from 'shared/lib/analytics/interface';

class AnalyticsService implements AnalyticsServiceInterface<string> {
  setUser = (_: string) => Promise.resolve();

  logout = () => Promise.resolve();

  logEvent = (_: string) => Promise.resolve();

  setIsEnabled = (_: boolean) => Promise.resolve();
}

export const AnalyticsServiceInstance = new AnalyticsService();
