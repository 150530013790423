import { GenericHook } from '../../../common/hooks/genericHook.type';
import { UrlOptions } from '../../../common/types/urlOptions.type';
import { buildPrismicApiInformationQueryHook } from './prismicApiInformation/usePrismicApiInformation.query';

export type UsePrismicQuery = GenericHook<ReturnType<typeof buildPrismicQueryHook>>;

export const buildPrismicQueryHook = (urlOptions: UrlOptions) => {
  const usePrismicApiInformationQuery = buildPrismicApiInformationQueryHook(urlOptions);

  return () => {
    const prismicApiInformationQueryResult = usePrismicApiInformationQuery();

    const prismicMasterReference = prismicApiInformationQueryResult.data?.references?.find(
      ({ isMasterReference }) => isMasterReference
    )?.reference;

    return {
      prismicMasterReference,
      prismicApiInformationQueryResult,
    };
  };
};
