export const adaptRefreshTokenRequestParams = (
  refreshToken: string,
  authFlow: string,
  clientID: string
) =>
  JSON.stringify({
    AuthFlow: authFlow,
    ClientId: clientID,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  });
