import { AuthenticationSwitchNavigator } from '../modules/authentification/components/AuthenticationSwitchNavigator';
import { AuthenticatedAppRouter } from './AuthenticatedAppRouter';
import { UnauthenticatedAppRouter } from './UnauthenticatedAppRouter';

export const RootNavigator = () => {
  return (
    <AuthenticationSwitchNavigator
      AuthenticatedNavigator={AuthenticatedAppRouter}
      UnauthenticatedNavigator={UnauthenticatedAppRouter}
    />
  );
};
