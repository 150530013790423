import { SharedEnvironment } from './types/environment.type';

// TODO: using preprod for testing purpose. replace with right values when ready.
export const env: SharedEnvironment = {
  API_GATEWAY_ENDPOINT_URL: 'https://api.partenaire.pmu.fr',
  COGNITO_ENDPOINT_URL: 'https://cognito-idp.eu-west-3.amazonaws.com',
  COGNITO_ENDPOINT_HEADER_TARGET: 'AWSCognitoIdentityProviderService.InitiateAuth',
  COGNITO_ENDPOINT_HEADER_CONTENT_TYPE: 'application/x-amz-json-1.1',
  COGNITO_ENDPOINT_CLIENT_ID: '409aqti7pmvh3k7d4g2isd8f42',
  PRISMIC_ENDPOINT_URL: 'https://pmu-partenaires.prismic.io/api/v2',
  PRISMIC_PERMANENT_ACCESS_TOKEN:
    'MC5ZY0JjYnhZQUFDNEFXWUFD.77-9e--_vRDvv70C77-977-9fD3vv71oEu-_vXwyGzLvv73vv71a77-977-977-977-977-977-9aF_vv73vv70z',
  IZZILI_ESPACE_GESTION_URL: 'https://espacegestionpmu.ebizperformance.com',
  PULSE_URL: 'https://www.challenge-reseaupmu.fr',
};
