import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { HtmlPagesList } from '../../../types/htmlPagesList.type';
import { adaptHtmlPages } from '../../adapters/adaptHtmlPages';
import { getPageHtmlListApi } from './getHtmlPageListApi';

export const getHtmlPagesConnector = (
  prismicEnvironmentRef: string,
  urlOptions: UrlOptions
): Promise<HtmlPagesList> =>
  getPageHtmlListApi(prismicEnvironmentRef, urlOptions).then(adaptHtmlPages);
