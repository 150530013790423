import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReferrerContext } from '../components/AuthenticationCallbackContext';
import { AuthenticatedRoutePath } from '../types/AuthenticatedRoutePath.type';

export const useRedirectToReferrerPage = () => {
  const { popReferrerData } = useReferrerContext();
  const navigate = useNavigate();
  useEffect(() => {
    const data = popReferrerData();
    if (data) {
      navigate({
        pathname: data.path,
        search: data.params,
      });
    } else {
      navigate(AuthenticatedRoutePath.home);
    }
  }, []);
};
