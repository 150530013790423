import { RawRole } from '../types/RawRole.type';
import { Role } from '../types/Role.type';

export const roleMapping: Record<RawRole, Role> = {
  'Préposé +': 'attendant +',
  Collaborateur: 'collaborator',
  Partenaire: 'partner',
  Préposé: 'attendant',
  Comptable: 'accountant',
};
