import wretch from 'wretch';
import { handleRequestError } from '../../../../../ui/api/error/helpers/handleRequestError';
import { UrlOptions } from '../../../../common/types/urlOptions.type';
import { RequestNewPasswordMutationParameters } from './types/RequestNewPasswordMutationParameters';
import { RequestNewPasswordResponse } from './types/RequestNewPasswordMutationResponse';
import { getRequestNewPasswordUrl } from './urls';

export const requestNewPasswordApi = async (
  params: RequestNewPasswordMutationParameters,
  urlOptions: UrlOptions
): Promise<RequestNewPasswordResponse> => {
  try {
    const url = getRequestNewPasswordUrl(urlOptions);
    // TODO: we should use the getUnauthenticatedApi from baseApi
    // export function getUnauthenticatedApi(url: string, options?: any) {
    //   return Api.options(options || {}).url(url);
    // }
    // Lorsque l'on utilisait cette fonction, les tests ne passaient pas car la fonction getUnauthenticatedApi n'était pas mocké
    // (la fonction était undefined). Donc on ne pouvait pas mocké le call API car pas d'url récupéré

    return await wretch(url)
      .query({
        username: params.username,
      })
      .post()
      .res();
  } catch (e: any) {
    return handleRequestError(e);
  }
};
