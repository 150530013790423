import React, { createContext, FunctionComponent, useContext } from 'react';
import { ConnectorOptions } from '../../../common/types/connectorOptions.type';
import { buildUseLoginState, UseLoginState } from '../hooks/useLoginState/useLoginState';

const AuthenticationContext = createContext<ReturnType<UseLoginState>>({
  globalUser: {
    isLoggedIn: false,
    pointOfSales: null,
    currentPointOfSale: null,
    role: null,
    isLoading: true,
  },
  setGlobalUser: () => undefined,
  setCurrentPointOfSale: () => false,
});

export const buildAuthenticationContextProvider = (
  connectorOptions: ConnectorOptions
): FunctionComponent => {
  const useLoginState = buildUseLoginState(connectorOptions);

  return ({ children }) => {
    const loginState = useLoginState();

    return (
      <AuthenticationContext.Provider value={loginState}>{children}</AuthenticationContext.Provider>
    );
  };
};

export const useAuthenticationContext = () => {
  return useContext(AuthenticationContext);
};
