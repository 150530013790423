import { AuthAppRoutes } from '../types/AuthAppRoutes.type';
import { UnauthenticatedRoutePath } from '../types/UnauthenticatedRoutePath.type';

export const authUrlBuilder = (targetPath: AuthAppRoutes): string => {
  const authAppUrl = `${AUTH_APP_BASE_URL}${targetPath}`;
  const callbackUrlParamEncoded = encodeURIComponent(routeUrlBuilder());
  const authUrl = `${authAppUrl}?callbackUrl=${callbackUrlParamEncoded}`;

  return authUrl;
};

const routeUrlBuilder = (): string => {
  return window.location.origin + UnauthenticatedRoutePath.authCallback;
};
