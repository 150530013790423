import { EnvironmentNames, sharedEnvironments } from '../../environment';

import {
  buildHtmlPagesQueryHook,
  UseHtmlPagesQuery,
} from './http/queries/htmlPage/useHtmlPages.query';

export class PrismicHtmlPagesModule {
  useHtmlPagesQuery: UseHtmlPagesQuery;

  constructor(envName: EnvironmentNames) {
    const env = sharedEnvironments[envName];
    const urlOptions = { env };

    this.useHtmlPagesQuery = buildHtmlPagesQueryHook(urlOptions);
  }
}
