import { queryClient } from 'shared/lib/reactQuery/client';
import { AnalyticsServiceInterface } from '../../../../../lib/analytics/interface';
import { ConnectorOptions } from '../../../../common/types/connectorOptions.type';
import { useAuthenticationContext } from '../../AuthenticationContext/AuthenticationContext';

export type UseLogoutRoutine = ReturnType<typeof logoutRoutineBuilder>;

export const logoutRoutineBuilder =
  (connectorOptions: ConnectorOptions, analyticsService: AnalyticsServiceInterface<string>) =>
  (): {
    handleLogout: () => Promise<void>;
  } => {
    const { setGlobalUser } = useAuthenticationContext();

    const handleLogout = async () => {
      await connectorOptions.authTokensService.resetAuthTokens();

      await queryClient.clear();

      await analyticsService.logout();

      setGlobalUser({
        isLoading: false,
        isLoggedIn: false,
        pointOfSales: null,
        currentPointOfSale: null,
        role: null,
      });
    };

    return { handleLogout };
  };
