import { AnalyticsServiceInterface } from '../../../../../lib/analytics/interface';
import { CrashlyticsServiceInterface } from '../../../../../lib/crashlytics/interface';
import { ConnectorOptions } from '../../../../common/types/connectorOptions.type';
import { decodeAccessToken } from '../../../helpers/decodeAccessToken/decodeAccessToken';
import { AuthTokens } from '../../../services/authTokens/types';
import { useAuthenticationContext } from '../../AuthenticationContext/AuthenticationContext';
import { logoutRoutineBuilder } from '../useLogoutRoutine/useLogoutRoutine';

export type UseLoginRoutine = ReturnType<typeof loginRoutineBuilder>;

export const loginRoutineBuilder = (
  connectorOptions: ConnectorOptions,
  analyticsService: AnalyticsServiceInterface<string>,
  crashlyticsServiceInstance: CrashlyticsServiceInterface
) => {
  const useLogoutRoutine = logoutRoutineBuilder(connectorOptions, analyticsService);

  return (): {
    handleLogin: (authTokens: AuthTokens) => Promise<void>;
  } => {
    const { setGlobalUser } = useAuthenticationContext();
    const { handleLogout } = useLogoutRoutine();

    const handleLogin = async (authTokens: AuthTokens) => {
      try {
        await connectorOptions.authTokensService.setAuthTokens(authTokens);

        const decodedAccessToken = decodeAccessToken(authTokens.accessToken);
        const currentPointOfSale = decodedAccessToken.pointOfSales[0];

        setGlobalUser({
          isLoading: false,
          isLoggedIn: true,
          pointOfSales: decodedAccessToken.pointOfSales,
          role: decodedAccessToken.role,
          currentPointOfSale,
        });

        await analyticsService.setUser(currentPointOfSale.id);
        await crashlyticsServiceInstance.setUser(currentPointOfSale.id);
      } catch (_) {
        await handleLogout();
      }
    };

    return { handleLogin };
  };
};
