import React, { createContext, FunctionComponent, useContext } from 'react';
import { CrashlyticsServiceInterface } from '../../../../lib/crashlytics/interface';
import { Role } from '../../http/mutations/login/types/Role.type';
import { PointOfSaleBasicInformations } from '../../types/user';
import { useAuthenticationContext } from '../AuthenticationContext/AuthenticationContext';

export const AuthenticatedContext = createContext<{
  pointOfSale: PointOfSaleBasicInformations;
  role: Role;
}>({
  pointOfSale: { id: '', contractId: '' },
  role: 'attendant',
});

export const buildAuthenticatedContextProvider =
  (crashlyticsServiceInstance: CrashlyticsServiceInterface): FunctionComponent =>
  ({ children }) => {
    const { globalUser } = useAuthenticationContext();

    if (!globalUser.currentPointOfSale || !globalUser.role) {
      crashlyticsServiceInstance.recordError(
        new Error(
          `GlobalUser is missing currentPointOfSale or Role. POS: ${JSON.stringify(
            globalUser.currentPointOfSale
          )}; Role: ${globalUser.role}`
        )
      );

      return null;
    }

    const { currentPointOfSale, role } = globalUser;

    return (
      <AuthenticatedContext.Provider value={{ pointOfSale: currentPointOfSale, role }}>
        {children}
      </AuthenticatedContext.Provider>
    );
  };

export const useAuthenticatedContext = () => {
  return useContext(AuthenticatedContext);
};
