import wretch from 'wretch';
import { PaginationOptions } from '../../../../lib/prismic/types/PaginationOptions.type';
import { UrlOptions } from '../../../common/types/urlOptions.type';

const Api = wretch();

export const getSearchApi = (
  options: UrlOptions,
  prismicEnvironmentRef: string,
  predicates: string[],
  paginationOptions?: PaginationOptions
) => {
  return Api.url(`${options.env.PRISMIC_ENDPOINT_URL}/documents/search`).query({
    ref: prismicEnvironmentRef,
    access_token: options.env.PRISMIC_PERMANENT_ACCESS_TOKEN,
    q: predicates,
    ...(paginationOptions || {}),
  });
};
