import { FunctionComponent } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { Cheatcode } from '../components/Cheatcode/Cheatcode';
import { useRedirectToReferrerPage } from '../modules/authentification/hooks/useRedirectToReferrerPage';
import { AuthenticatedRoutePath } from '../modules/authentification/types/AuthenticatedRoutePath.type';
import { AuthenticatedContextProvider } from '../modules/authentification/web.implementation';
import { CguPage } from '../pages/Cgus/CguPage';
import { PrivacyPolicyPage } from '../pages/PrivacyPolicy/PrivacyPolicyPage';

const AuthenticatedAppRoutes: FunctionComponent = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Cheatcode />,
    },
    {
      path: AuthenticatedRoutePath.cgu,
      element: <CguPage />,
    },
    {
      path: AuthenticatedRoutePath.privacyPolicy,
      element: <PrivacyPolicyPage />,
    },
    {
      path: AuthenticatedRoutePath.home,
      element: <Cheatcode />,
    },
  ]);

  useRedirectToReferrerPage();

  return routes;
};

export const AuthenticatedAppRouter: FunctionComponent = () => {
  return (
    <AuthenticatedContextProvider>
      <Router>
        <AuthenticatedAppRoutes />
      </Router>
    </AuthenticatedContextProvider>
  );
};
