import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReferrerContext } from '../components/AuthenticationCallbackContext';
import { UnauthenticatedRoutePath } from '../types/UnauthenticatedRoutePath.type';

export const useSaveReferrerPage = () => {
  const { pushReferrerData } = useReferrerContext();
  const navigate = useNavigate();
  const location = useLocation();
  const referrerPageRoute = location.pathname;

  useEffect(() => {
    if (!isPublicRoute(referrerPageRoute)) {
      pushReferrerData({
        path: referrerPageRoute,
        params: location.search,
      });
      navigate(UnauthenticatedRoutePath.login);
    }
  }, []);
};

const isPublicRoute = (route: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.values(UnauthenticatedRoutePath).includes(route as UnauthenticatedRoutePath);
};
