import { AnalyticsServiceInterface } from '../../../../../lib/analytics/interface';
import { CrashlyticsServiceInterface } from '../../../../../lib/crashlytics/interface';
import { ConnectorOptions } from '../../../../common/types/connectorOptions.type';
import { mergeAuthTokens } from '../../../http/middlewares/refreshTokenMiddleware/mergeAuthTokens.helper';
import { buildRefreshTokenConnector } from '../../../http/mutations/refreshToken/refreshTokenConnector';
import { loginRoutineBuilder } from '../useLoginRoutine/useLoginRoutine';
import { logoutRoutineBuilder } from '../useLogoutRoutine/useLogoutRoutine';

export type UseRefreshTokenRoutine = ReturnType<typeof refreshTokenRoutineBuilder>;

export const refreshTokenRoutineBuilder = (
  connectorOptions: ConnectorOptions,
  analyticsService: AnalyticsServiceInterface<string>,
  crashlyticsServiceInstance: CrashlyticsServiceInterface
) => {
  const useLoginRoutine = loginRoutineBuilder(
    connectorOptions,
    analyticsService,
    crashlyticsServiceInstance
  );
  const useLogoutRoutine = logoutRoutineBuilder(connectorOptions, analyticsService);

  const refreshTokenConnector = buildRefreshTokenConnector(connectorOptions.env);

  return (): {
    handleRefreshToken: () => Promise<void>;
  } => {
    const { handleLogin } = useLoginRoutine();
    const { handleLogout } = useLogoutRoutine();

    const handleRefreshToken = async () => {
      try {
        const currentAuthTokens = await connectorOptions.authTokensService.getAuthTokens();
        if (!currentAuthTokens) {
          throw new Error();
        }

        const refreshingResponse = await refreshTokenConnector({
          refreshToken: currentAuthTokens.refreshToken,
        });

        const newAuthTokens = mergeAuthTokens(refreshingResponse, currentAuthTokens);

        handleLogin(newAuthTokens);
      } catch (_) {
        await handleLogout();
      }
    };

    return { handleRefreshToken };
  };
};
