import { FunctionComponent } from 'react';
import { EnvironmentNames, sharedEnvironments } from '../../environment';
import { AnalyticsServiceInterface } from '../../lib/analytics/interface';
import { CrashlyticsServiceInterface } from '../../lib/crashlytics/interface';
import { buildAuthenticatedContextProvider } from './context/AuthenticatedContext/AuthenticatedContext';
import { buildAuthenticationContextProvider } from './context/AuthenticationContext/AuthenticationContext';
import {
  loginRoutineBuilder,
  UseLoginRoutine,
} from './context/hooks/useLoginRoutine/useLoginRoutine';
import {
  logoutRoutineBuilder,
  UseLogoutRoutine,
} from './context/hooks/useLogoutRoutine/useLogoutRoutine';
import {
  refreshTokenRoutineBuilder,
  UseRefreshTokenRoutine,
} from './context/hooks/useRefreshTokenRoutine/useRefreshTokenRoutine';
import {
  buildRequestNewPasswordMutationHook,
  UseRequestNewPasswordMutation,
} from './http/mutations/requestNewPassword/useRequestNewPassword.mutation';
import { AuthTokensServiceInterface } from './services/authTokens/types';

export class AuthenticationModule {
  AuthenticationContextProvider: FunctionComponent;
  AuthenticatedContextProvider: FunctionComponent;
  useLoginRoutine: UseLoginRoutine;
  useLogoutRoutine: UseLogoutRoutine;
  useRequestNewPassword: UseRequestNewPasswordMutation;
  useRefreshTokenRoutine: UseRefreshTokenRoutine;

  constructor(
    authTokensService: AuthTokensServiceInterface,
    analyticsService: AnalyticsServiceInterface<string>,
    envName: EnvironmentNames,
    crashlyticsServiceInstance: CrashlyticsServiceInterface
  ) {
    const env = sharedEnvironments[envName];
    const connectorOptions = { env, authTokensService };

    this.AuthenticatedContextProvider = buildAuthenticatedContextProvider(
      crashlyticsServiceInstance
    );
    this.AuthenticationContextProvider = buildAuthenticationContextProvider(connectorOptions);
    this.useLoginRoutine = loginRoutineBuilder(
      connectorOptions,
      analyticsService,
      crashlyticsServiceInstance
    );
    this.useLogoutRoutine = logoutRoutineBuilder(connectorOptions, analyticsService);
    this.useRequestNewPassword = buildRequestNewPasswordMutationHook(connectorOptions);
    this.useRefreshTokenRoutine = refreshTokenRoutineBuilder(
      connectorOptions,
      analyticsService,
      crashlyticsServiceInstance
    );
  }
}
