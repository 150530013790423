import { RawRefreshTokenResponseData } from '../types/RawRefreshTokenResponseData';
import { RefreshTokenResponseData } from '../types/RefreshTokenResponseData';

export const adaptRefreshTokenResponse = (
  response: RawRefreshTokenResponseData
): RefreshTokenResponseData => {
  const { ExpiresIn, IdToken, TokenType } = response.AuthenticationResult;

  return {
    authenticationResult: {
      accessToken: IdToken,
      expiresIn: ExpiresIn,
      tokenType: TokenType,
    },
  };
};
