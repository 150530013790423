/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { EnvironmentNames } from 'shared/environment/index';
import { AnalyticsServiceInterface } from 'shared/lib/analytics/interface';
import { AuthenticationModule } from 'shared/modules/authentication/index';
import { AnalyticsServiceInstance } from './services/Analytics.service';
import { AuthTokensServiceInstance } from './services/AuthTokens.service';
import { CrashlyticsServiceInstance } from './services/Crashlytics.service';

const environment = window.ENV as EnvironmentNames;

const Authentication = new AuthenticationModule(
  AuthTokensServiceInstance,
  AnalyticsServiceInstance as AnalyticsServiceInterface<string>,
  environment,
  CrashlyticsServiceInstance
);

export const {
  useLoginRoutine,
  useLogoutRoutine,
  AuthenticationContextProvider,
  AuthenticatedContextProvider,
  useRequestNewPassword,
} = Authentication;
