import {
  AuthTokens,
  AuthTokensServiceInterface,
} from 'shared/modules/authentication/services/authTokens/types';
import { AUTH_TOKENS_KEY } from 'shared/modules/common/constants/authTokens.constants';

import { getItem, setItem } from '../helpers/manageLocalStorage';

class AuthTokensService implements AuthTokensServiceInterface {
  setAuthTokens(authTokens: AuthTokens) {
    setItem(AUTH_TOKENS_KEY, authTokens);

    return Promise.resolve();
  }

  getAuthTokens() {
    const authTokens = getItem<AuthTokens>(AUTH_TOKENS_KEY);

    if (authTokens) {
      return Promise.resolve(authTokens);
    }

    return Promise.resolve(null);
  }

  resetAuthTokens() {
    return Promise.resolve();
  }
}

export const AuthTokensServiceInstance = new AuthTokensService();
