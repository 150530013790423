import { HtmlPagesList } from '../../types/htmlPagesList.type';
import { RawHtmlPagesList } from '../queries/htmlPage/types/RawHtmlPagesList.type';

export const adaptHtmlPages = (results: RawHtmlPagesList): HtmlPagesList => {
  const pages = results.results;

  return pages.map(page => ({
    id: page.uid,
    htmlTitle: page.data.html_title,
    htmlContent: page.data.html_content,
  }));
};
